
import { IResponse } from '@/common/http'
import { computed, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

interface ITableData {
  id: number,
  title: string,
  content: string,
  createtime: string,
  // eslint-disable-next-line camelcase
  user_news: [] | null
}

interface IState {
  initLoad: boolean,
  page: number,
  pageSize: number,
  total: number,
  tableData: ITableData | null
  activeName: number
}

export default defineComponent({
  name: 'News',
  setup () {
    const state = reactive<IState>({
      initLoad: false,
      page: 1,
      pageSize: 15,
      total: 0,
      tableData: null,
      activeName: 0
    })
    const stateAsRefs = toRefs<IState>(state)
    const { proxy }: any = getCurrentInstance()
    const store = useStore()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)

    const getTableList = (): void => {
      proxy.$axios.get('/news/all', {
        page: state.page,
        page_size: state.pageSize
      }).then((res: IResponse) => {
        state.total = res.data.count
        state.tableData = res.data.lists
        setTimeout(() => {
          state.initLoad = true
        }, 100)
      })
    }
    getTableList()

    const handleSizeChange = (val: number): void => {
      state.pageSize = val
      getTableList()
    }
    const handleCurrentChange = (val: number): void => {
      state.page = val
      getTableList()
    }

    return {
      ...stateAsRefs,
      responsively,
      handleSizeChange,
      handleCurrentChange
    }
  }
})

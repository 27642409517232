<template>
  <el-main class="news">
    <el-card v-show="initLoad">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item v-for="(item, index) in tableData" :title="item.title" :name="index" :key="index">
          <div class="content" v-html="item.content"></div>
          <div class="time">{{ item.createtime }}</div>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <el-card v-show="initLoad" class="page__footer">
      <el-pagination
        background
        :small="responsively"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total"
      />
    </el-card>
  </el-main>
</template>

<script lang="ts">
import { IResponse } from '@/common/http'
import { computed, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

interface ITableData {
  id: number,
  title: string,
  content: string,
  createtime: string,
  // eslint-disable-next-line camelcase
  user_news: [] | null
}

interface IState {
  initLoad: boolean,
  page: number,
  pageSize: number,
  total: number,
  tableData: ITableData | null
  activeName: number
}

export default defineComponent({
  name: 'News',
  setup () {
    const state = reactive<IState>({
      initLoad: false,
      page: 1,
      pageSize: 15,
      total: 0,
      tableData: null,
      activeName: 0
    })
    const stateAsRefs = toRefs<IState>(state)
    const { proxy }: any = getCurrentInstance()
    const store = useStore()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)

    const getTableList = (): void => {
      proxy.$axios.get('/news/all', {
        page: state.page,
        page_size: state.pageSize
      }).then((res: IResponse) => {
        state.total = res.data.count
        state.tableData = res.data.lists
        setTimeout(() => {
          state.initLoad = true
        }, 100)
      })
    }
    getTableList()

    const handleSizeChange = (val: number): void => {
      state.pageSize = val
      getTableList()
    }
    const handleCurrentChange = (val: number): void => {
      state.page = val
      getTableList()
    }

    return {
      ...stateAsRefs,
      responsively,
      handleSizeChange,
      handleCurrentChange
    }
  }
})
</script>

<style lang="scss" scoped>
  .news {
    :deep(.el-collapse-item__header) {
      font-size: 16px;
      font-weight: 700;
    }

    .content {
      font-size: 14px;
    }

    .time {
      color: #909399;
      font-size: 12px;
      text-align: right;
    }
  }

  .page {
    @mixin cardBody {
      :deep(.el-card__body) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 8px;
      }
    }
    &__footer {
      margin: 12px 0 0;
      @include cardBody;
    }
  }
</style>
